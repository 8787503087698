import React, { useState } from "react";
import { Grid } from "@mui/material";
import LeftPanel from "./Components/LeftPanel";
import "./Layout.scss";
import Tour from "./Components/Tour.js";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#55828b",
      dark: "#44686f",
    },
    secondary: {
      main: "#5a0b4d",
      highlight: "#ee4266",
    },
    tertiary: {
      main: "#393939",
    },

  },
  typography: {
    fontFamily: "'Montserrat', 'sans-serif'",
    pageHeading: {
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: 1.2,
      color: "#393939",
    },
    sectionHeading: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.3,
      color: "#5a0b4d",
    },
    primaryText: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.5,
      color: "#170313",
      display: 'block'
    },
    secondaryText: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.5,
      color: "#170313",
      display: 'block'
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none", // Disable text transformation globally for all buttons
        },
      },
    },
  },
});

const Layout = ({ children }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsNavOpen(true);
  };

  const handleMouseLeave = () => {
    setIsNavOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container style={{ height: "100vh" }}>
        <Grid item xs={isNavOpen ? 1 : 0.5}>
          <LeftPanel
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            isNavOpen={isNavOpen}
          />
        </Grid>

        <Grid
          item
          xs={isNavOpen ? 11 : 11.5}
          style={{
            transition: "margin-left 0.35s",
            marginLeft: isNavOpen ? 170 : 80,
            height: "100%",
          }}
        >
          <Grid container style={{ height: "100%" }}>
            <Grid item xs={12} style={{ height: "100%" }}>
              {children}
            </Grid>
          </Grid>
        </Grid>

        {/* <Tour onEnd={() => {}} setIsNavOpen={setIsNavOpen} /> */}
      </Grid>
    </ThemeProvider>
  );
};

export default Layout;
