import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  TablePagination,
  CircularProgress,
  Alert,
  Link
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AddIcon from "@mui/icons-material/Add";
import { api } from "../store";

const RfpRow = ({ rfp }) => {
  const [open, setOpen] = useState(false);
  const formattedDate = new Date(rfp.dueDate).toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell sx={{ width: "5%" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          sx={{
            maxWidth: "15rem",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "40%"
          }}
        >
          {rfp.title}
        </TableCell>
        <TableCell
          sx={{
            maxWidth: "12rem",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "35%"
          }}
        >
          <Link target="_blank" rel="noopener noreferrer" href={rfp.sourceURL} color="primary.main">
            {rfp.sourceURL}
          </Link>
        </TableCell>
        <TableCell sx={{ width: "20%", align: "left" }}>
          {formattedDate}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 2,
                backgroundColor: "rgba(0, 0, 0, 0.02)",
                padding: 2,
                borderRadius: 2
              }}
            >
              <Typography
                variant="secondaryHeading"
                gutterBottom
                component="div"
                sx={{ color: "primary.main" }}
              >
                {rfp.title}
              </Typography>
              <Typography
                variant="primaryText"
                gutterBottom
                sx={{
                  whiteSpace: "pre-wrap",
                  lineHeight: 1.6
                }}
              >
                {rfp.extract}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const Opportunities = () => {
  const [opportunities, setOpportunities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    fetchOpportunities();
  }, []);

  const fetchOpportunities = async () => {
    try {
      setLoading(true);
      const response = await api.get("/capture/getOpportunities");
      setOpportunities(response.data.opportunities || []);
      setError(null);
    } catch (err) {
      setError("Failed to fetch opportunities");
      console.error("Error fetching opportunities:", err);
    } finally {
      setLoading(false);
    }
  };

  // Flatten opportunities array for pagination
  const flattenedOpportunities = opportunities;


  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return (
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh"
      }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ margin: "1.5rem 2rem" }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ margin: "1.5rem 2rem" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
          mt: 4
        }}
      >
        <Typography variant="pageHeading">Opportunities</Typography>
      </Box>
      <Paper elevation={1}
        sx={{
          width: "100%",
          overflow: "hidden",
          borderRadius: 2,
          mt: 7
        }}
      >
        <TableContainer sx={{ maxHeight: "calc(100vh - 12rem)" }}>
          <Table stickyHeader aria-label="sticky collapsible table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    backgroundColor: "background.paper",
                    width: "5%"
                  }}
                />
                <TableCell
                  sx={{
                    backgroundColor: "background.paper",
                    width: "40%",
                    fontWeight: "bold"
                  }}
                >
                  Title
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: "background.paper",
                    width: "40%",
                    fontWeight: "bold"
                  }}
                >
                  Source
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: "background.paper",
                    width: "20%",
                    fontWeight: "bold"
                  }}
                >
                  Due Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {flattenedOpportunities.length > 0 ? (
                flattenedOpportunities
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((rfp, index) => (
                    <RfpRow
                      key={`${rfp.opportunityId}-${index}`}
                      rfp={rfp}
                    />
                  ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    sx={{
                      textAlign: "center",
                      py: 8 // Increased padding for better visual appearance
                    }}
                  >
                    <Typography variant="body1" color="text.secondary">
                      No opportunities found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {flattenedOpportunities.length > 0 && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={flattenedOpportunities.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              borderTop: 1,
              borderColor: "divider"
            }}
          />
        )}
      </Paper>
    </Box>
  );
};

export default Opportunities;