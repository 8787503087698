import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./RFPLoader.scss";
import "./Dashboard.scss";
import { api, api_external } from "../store";
import env from "../config";
import { Typography } from "@mui/material";

const RFPLoader = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [progress, setProgress] = useState({
    process_rfx_files: 0,
    fetch_checklist: 0,
    generate_draft: 0,
  });
  const [documents, setDocuments] = useState([]);
  const [userArchiveIDs, setUserArchiveIDs] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    const projectId = state.projectId;

    const fetchDocuments = async () => {
      try {
        const fileList = await api.get("file/getFiles", {
          params: { projectId },
          headers: { "Cache-Control": "no-cache" },
        });
        setDocuments(fileList.data);
        return fileList.data;
      } catch (error) {
        console.error("Error fetching documents:", error);
        return [];
      }
    };

    const simulateProgress = (key, cap, incrementValue, intervalTime) => {
      let interval;
      // The amount of progress to add each time

      interval = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress[key] + incrementValue;
          if (newProgress >= cap) {
            clearInterval(interval);
            return { ...prevProgress, [key]: cap }; // Ensure it stops exactly at the cap
          }
          return { ...prevProgress, [key]: newProgress };
        });
      }, intervalTime);

      return interval;
    };

    const processRFP = async () => {
      const processInterval = simulateProgress("process_rfx_files", 90, 1, 600);
      setError(false);
      try {
        const requestData = {
          project_id: projectId,
        };
        await api_external.post(`${env.salesPubAPI}/process-project`, requestData);
        clearInterval(processInterval);
        setProgress((prevProgress) => ({
          ...prevProgress,
          process_rfx_files: 100,
        }));
      } catch (error) {
        console.error(`Error processing rfp files:`, error);
        setError(true);
        clearInterval(processInterval);
      }
    };

    const fetchRFPChecklist = async (project_id) => {
      const checklistInterval = simulateProgress("fetch_checklist", 96, 1, 800);
      setError(false);
      try {
        const requestData = { project_id };
        await api_external.post(
          `${env.salesPubAPI}/generate-rfx-checklist`,
          requestData
        );
        clearInterval(checklistInterval);
        setProgress((prevProgress) => ({
          ...prevProgress,
          fetch_checklist: 100,
        }));
      } catch (error) {
        console.error("Error fetching RFP checklist:", error);
        setError(true);
        clearInterval(checklistInterval);
      }
    };

    const generateRFPDraft = async (project_id, archiveIds) => {
      const draftInterval = simulateProgress("generate_draft", 92, 1, 1200);
      setError(false);
      try {
        const requestData = { project_id, archive_ids: archiveIds };
        await api_external.post(
          `${env.salesPubAPI}/generate-rfx-response`,
          requestData
        );
        clearInterval(draftInterval);
        setProgress((prevProgress) => ({
          ...prevProgress,
          generate_draft: 100,
        }));
      } catch (error) {
        console.error("Error generating RFP draft:", error);
        setError(true);
        clearInterval(draftInterval);
      }
    };

    const loadDocumentsAndProcessRFP = async (archiveIds) => {
      const docs = await fetchDocuments();
      if (docs.length > 0) {

        setProgress({
          process_rfx_files: 0,
          fetch_checklist: 0,
          generate_draft: 0,
        });

        await processRFP(projectId);

        // Start checklist and draft after processing files
        fetchRFPChecklist(projectId);
        generateRFPDraft(projectId, archiveIds);
      }
    };

    const getUserArchivesId = async () => {
      try {
        const response = await api.get("/archives/getUserArchivesId");
        const archiveIds =
          response.data.archives.length > 0
            ? response.data.archives.map((archive) => archive.id)
            : [];
        setUserArchiveIDs(archiveIds);

        loadDocumentsAndProcessRFP(archiveIds);
      } catch (error) {
        console.error("Error fetching user archives:", error);
      }
    };

    getUserArchivesId();
  }, [state.projectId]);

  // Automatically navigate when all progress is complete
  useEffect(() => {
    if (
      progress.process_rfx_files === 100 &&
      progress.fetch_checklist === 100 &&
      progress.generate_draft === 100
    ) {
      navigate("/rfp-editor", {
        replace: true,
        state: {
          projectId: state.projectId,
          projectName: state.projectName,
        },
      });
    }
  }, [progress, navigate, state.projectId, state.projectName]);

  return (
    <div className="rfp-loader">
      <div className="page-header">
        <div className="page-title-container">
          <span className="page-title">Preparing Data</span>
        </div>
      </div>
      {error && (
        <Typography variant="body2" style={{ color: "red" }}>
          Error ocurred during processing RFX files. Please contact
          support@xpub.ai for more information.
        </Typography>
      )}
      <div className="rfp-loader__progress-container">
        <div className="rfp-loader__progress-bars">
          <div className="rfp-loader__progress-item">
            <div className="rfp-loader__progress-item-label">
              Processing files
            </div>
            <div className="rfp-loader__progress-item-bar">
              <div
                className="rfp-loader__progress-item-bar-fill"
                style={{ width: `${progress.process_rfx_files}%` }}
              ></div>
            </div>
          </div>
          <div className="rfp-loader__progress-item">
            <div className="rfp-loader__progress-item-label">
              Building checklist
            </div>
            <div className="rfp-loader__progress-item-bar">
              <div
                className="rfp-loader__progress-item-bar-fill"
                style={{ width: `${progress.fetch_checklist}%` }}
              ></div>
            </div>
          </div>
          <div className="rfp-loader__progress-item">
            <div className="rfp-loader__progress-item-label">
              Generating draft
            </div>
            <div className="rfp-loader__progress-item-bar">
              <div
                className="rfp-loader__progress-item-bar-fill"
                style={{ width: `${progress.generate_draft}%` }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RFPLoader;
