import React, { useEffect, useState, useMemo } from "react";
import { TextField, Grid, Box, Typography, Button } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import { api } from "../store";
import FileUploader from "../Components/FileUploader";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";

const CreateRFXProject = () => {
  const navigate = useNavigate();

  const [projectName, setProjectName] = useState("");
  const [clientName, setClientName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [dueDate, setDueDate] = useState(null);
  const [errors, setErrors] = useState({});
  const [filesAndStatus, setFilesAndStatus] = useState([]);
  const [uploadErrorMessages, setUploadErrorMessages] = useState([]);
  const [uploadError, setUploadError] = useState(false);
  const [isRFXTagUsed, setIsRFXTagUsed] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [showTagErrors, setShowTagErrors] = useState(false);
  const [fileNumberExceeded, setFileNumberExceeded] = useState(false);
  const [newProjectId, setNewProjectId] = useState("");
  const [newProjectName, setNewProjectName] = useState("");

  const handleCancel = () => {
    navigate("/rfp-dashboard");
  };

  const updateFileUploaderStatus = (updatedFilesAndStatus) => {
    setUploadErrorMessages([]);
    setFilesAndStatus(updatedFilesAndStatus);
    setUploadError(false);
    setFileNumberExceeded(false);

    // Clear the "All files must have a tag assigned" error if all files now have tags
    if (updatedFilesAndStatus.every(file => file.tag)) {
      setErrors(prevErrors => ({
        ...prevErrors,
        filesAndStatus: prevErrors.filesAndStatus === "All files must have a tag assigned." ? "" : prevErrors.filesAndStatus
      }));
    }

    // Update the RFX tag usage
    const rfxFileExists = updatedFilesAndStatus.some((file) => file.tag === "RFX");
    setIsRFXTagUsed(rfxFileExists);

    // Clear the "At least one file must be tagged as 'RFX File'" error if an RFX file is now present
    if (rfxFileExists) {
      setErrors(prevErrors => ({
        ...prevErrors,
        filesAndStatus: prevErrors.filesAndStatus === "At least one file must be tagged as 'RFX File'." ? "" : prevErrors.filesAndStatus
      }));
    }
  };

  const handleFileNumberExceeded = () => {
    setFileNumberExceeded(true);
  };

  const validateTags = () => {
    const filesWithoutTags = filesAndStatus.filter(file => !file.tag);
    return filesWithoutTags.length === 0;
  };

  async function uploadFiles(filesWithTags, projectId) {
    const formData = new FormData();

    filesWithTags.forEach(({ file, tag }, index) => {
      formData.append(`files`, file);
      formData.append(`fileTags[${index}]`, tag);
    });
    formData.append("ownerId", projectId);

    try {
      const response = await api.post("file/uploadFiles", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return response.data;
    } catch (err) {
      console.error(`Failed to upload files`, err);
      throw err;
    }
  }

  const deleteAllFiles = async (projectId) => {
    try {
      const response = await api.delete(`file/deleteAllFiles/${projectId}`);
      return response.data;
    } catch (err) {
      console.error(`Failed to delete files for project ${projectId}`, err);
      throw err;
    }
  };

  const submitUploadFiles = async (projectId) => {
    let updatedFilesAndStatus = filesAndStatus.map((fs) => ({
      ...fs,
      status: "uploading",
    }));

    try {
      setFilesAndStatus(updatedFilesAndStatus);
      setUploadErrorMessages([]);

      const filesToUpload = updatedFilesAndStatus.map((fs) => ({
        file: fs.file,
        tag: fs.tag,
      }));

      const uploadResponse = await uploadFiles(filesToUpload, projectId);

      updatedFilesAndStatus = updatedFilesAndStatus.map((fs, index) => ({
        ...fs,
        status: uploadResponse[index].status === 200 ? "complete" : "edit",
      }));
      const errorMessages = uploadResponse
        .filter(response => response.status !== 200)
        .map(response => `${response.message || 'An error occurred during uploading file ${response.fileName}.'}`);
      if (errorMessages.length > 0) {
        deleteAllFiles(projectId);
      }

      setUploadErrorMessages(errorMessages);
    } catch (error) {
      console.error("Error during file upload process", error);
      setUploadError(true);
      updatedFilesAndStatus = updatedFilesAndStatus.map((fs) => ({
        ...fs,
        status: "edit",
      }));
      setUploadErrorMessages([`An error occurred during the upload process: ${error.message}`]);
    } finally {
      setFilesAndStatus(updatedFilesAndStatus);
      setIsUploading(false);
    }
    return updatedFilesAndStatus;
  };

  const handleCreate = async () => {
    setShowTagErrors(true);

    const newErrors = {};

    if (!projectName) {
      newErrors.projectName = "Project Name is required.";
    }

    if (!clientName) {
      newErrors.clientName = "Client Name is required.";
    }

    if (filesAndStatus.length === 0) {
      newErrors.filesAndStatus = "At least one file must be uploaded.";
    }

    const hasRFXFile = filesAndStatus.some((file) => file.tag === "RFX");
    if (!hasRFXFile) {
      newErrors.filesAndStatus =
        "At least one file must be tagged as 'RFX File'.";
    }

    if (projectDescription.length > 250) {
      newErrors.description = "Project description can only be 250 characters";
    }

    if (!validateTags()) {
      newErrors.filesAndStatus = "All files must have a tag assigned.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsUploading(true);

    try {
      let projectId = newProjectId;
      let pName = newProjectName;
      if (newProjectId === "") {
        const response = await api.post("/project/createRFXProject", {
          name: projectName.trim(),
          client: clientName.trim(),
          description: projectDescription.trim(),
          dueDate: dueDate ? dueDate.toISOString() : null,
        });
        projectId = response.data.projectId;
        pName = projectName.trim();
        setNewProjectId(projectId);
        setNewProjectName(pName)

      }

      const updatedFilesAndStatus = await submitUploadFiles(projectId);

      const allFilesUploaded = updatedFilesAndStatus.every(
        (fs) => fs.status === "complete"
      );


      if (allFilesUploaded) {
        navigate("/rfp-loader", {
          replace: true,
          state: {
            projectId: projectId,
            projectName: pName,
          },
        });
      } else {
        // If not all files were uploaded successfully, allow the user to retry
        setIsUploading(false);
      }
    } catch (error) {
      console.error("Error creating RFX project:", error);
      setIsUploading(false);
      setUploadError(true);
      setUploadErrorMessages([`An error occurred while creating the project: ${error.message}`]);
    }
  };

  const availableTagOptions = useMemo(() => {
    const options = [];
    options.push({ id: "RFX", label: "RFX File", enabled: !isRFXTagUsed });
    options.push({ id: "forms", label: "Form", enabled: true });
    return options;
  }, [isRFXTagUsed]);

  useEffect(() => {
    const rfxFileExists = filesAndStatus.some((file) => file.tag === "RFX");
    setIsRFXTagUsed(rfxFileExists);
  }, [filesAndStatus]);

  const renderTextField = (
    id,
    label,
    value,
    onChange,
    error,
    helperText,
    maxLength,
    showCounter = false
  ) => (
    <>
      <TextField
        id={id}
        label={label}
        variant="outlined"
        fullWidth
        value={value}
        onChange={onChange}
        error={!!error}
        helperText={helperText}
        inputProps={{ maxLength }}
        multiline={id === "project-description"}
        minRows={id === "project-description" ? 3 : 1}
      />
      {showCounter && (
        <Typography
          variant="caption"
          sx={{
            display: "block",
            textAlign: "right",
            color: value.length > maxLength ? "red" : "textSecondary",
          }}
        >
          {value.length}/{maxLength} characters
        </Typography>
      )}
    </>
  );

  return (
    <Grid sx={{ p: 4, height: "100%" }} container>
      <Grid item xs={12} sm={8} md={6}>
        <Typography variant="h6" gutterBottom>
          Create project
        </Typography>
        <Box sx={{ mb: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ marginTop: "1rem" }}>
              {renderTextField(
                "project-name",
                "Project Name",
                projectName,
                (e) => {
                  setProjectName(e.target.value);
                  setErrors({ ...errors, projectName: "" });
                },
                errors.projectName,
                errors.projectName,
                75
              )}
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "1rem" }}>
              {renderTextField(
                "project-client",
                "Client Name",
                clientName,
                (e) => {
                  setClientName(e.target.value);
                  setErrors({ ...errors, clientName: "" });
                },
                errors.clientName,
                errors.clientName,
                75
              )}
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "1rem" }}>
              {renderTextField(
                "project-description",
                "Project Description",
                projectDescription,
                (e) => setProjectDescription(e.target.value),
                errors.description,
                errors.description,
                250,
                true
              )}
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["DatePicker"]}
                  sx={{ paddingTop: "0.3rem" }}
                >
                  <DatePicker
                    label="Due Date"
                    value={dueDate}
                    onChange={(date) => setDueDate(date)}
                    minDate={dayjs()}
                    sx={{ paddingTop: 0 }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "1rem" }}>
              <Typography variant="primaryText">Add RFX Files</Typography>
              <Typography
                variant="secondaryText"
                style={{ marginBottom: "1rem" }}
              >
                Accepted formats: .txt, .docx, .pdf | Maximum file size is 2GB.
              </Typography>
              <FileUploader
                uploadErrorMessages={uploadErrorMessages}
                filesAndStatus={filesAndStatus}
                updateFileUploaderStatus={updateFileUploaderStatus}
                maxFiles={100}
                uploadError={uploadError}
                allowAudioFiles={false}
                acceptTypes={[".txt", ".docx", ".pdf"]}
                displayTags={true}
                tagOptions={availableTagOptions}
                showTagErrors={showTagErrors}
                fileNumberExceeded={fileNumberExceeded}
                onFileNumberExceeded={handleFileNumberExceeded}
              />
              {errors.filesAndStatus && (
                <Typography color="error" variant="primaryText" gutterBottom>{errors.filesAndStatus}</Typography>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",

            gap: "1rem",
            mt: 2,
            mb: 2,
          }}
        >
          <Button
            variant="secondary"
            onClick={handleCancel}
            sx={{
              textTransform: "none",
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleCreate}
            sx={{
              textTransform: "none",
            }}
          >
            Create
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CreateRFXProject;
